<template>
  <ul class="item-list">
    <li
      class="item"
      v-for="(item, index) in itemList"
      :class="[
        item.display_type === 3 ? 'had-stage' : 'no-stage',
        tripartite_type === 'VUE_APP_ZJG' ? 'zjg-li' : '',
      ]"
      :key="index"
      @click="clickItem(item, index)"
    >
      <div class="img-box">
        <yqt-image
          class="img"
          :src="item.base_pic | setHttps | ossProcess('style/w_480_h_auto')"
          fit="cover"
          lazyload
          alt="item pic"
        />
      </div>
      <p class="item-name">
        <span class="sourceName" v-if="item.source_type">
          {{ getSourceTypes(item.source_type) }}
        </span>
        {{ item.item_name }}
      </p>
      <p class="sales" v-if="salesStatus()">
        <span>已售</span>
        <span>{{ getSales(item.id, item.cost_price) }}</span>
      </p>
      <div
        class="priceWithBtn"
        v-if="price_status == 1 && !item.installment_plans"
      >
        <p class="item-price" v-if="businessType == 2">
          <span class="price">
            {{
              priceShow(
                item.display_type,
                item.price,
                item.max_integral_percentage
              )
            }}
          </span>
        </p>
        <p class="item-price" v-else>
          <span class="price">
            <span class="sign">&yen;</span>
            <span class="num">{{ item.price }}</span>
          </span>
        </p>
      </div>

      <div
        v-if="tripartite_type === 'VUE_APP_ZJG' && item.display_type !== 1"
        class="zjg-max"
      >
        积分最高抵扣{{ item.max_integral_percentage }}%
      </div>

      <div
        v-if="item.display_type === 3 && item.installment_plans"
        class="lusse"
      >
        <p>
          <span class="plans money">{{
            item.installment_plans.slice(-1)[0].avg_price
          }}</span>
          <span class="plans">元</span>/月起
        </p>
        <p class="item-price original-price" v-if="businessType == 2">
          <span class="price">
            {{
              priceShow(
                item.display_type,
                item.price,
                item.max_integral_percentage
              )
            }}
          </span>
        </p>
        <p class="item-price original-price" v-else>
          <span class="price">
            <span class="num">{{ item.price }}元</span>
          </span>
        </p>
      </div>

      <!-- /优惠信息 -->
      <div class="benefit-wrap" v-if="item.coupon_title">
        <yd-badge bgcolor="transparent" :color="theme">
          <span class="benefit">{{ item.coupon_title }}</span>
        </yd-badge>
      </div>
    </li>
  </ul>
</template>

<script>
const THEME = "#6C57FF";
import YqtImage from "@/components/image/Image";
import { getSales, priceShow } from "@/utils/goodsUtil";
import { mapState } from "vuex";
import { orderMixin } from "@/mixins/orderMixin.js";

export default {
  name: "ItemList",
  components: {
    YqtImage,
  },
  props: {
    itemList: Array,
  },
  mixins: [orderMixin],
  data() {
    return {
      theme: THEME,
    };
  },
  computed: {
    ...mapState({
      bid: (state) => state.businessInfo.id, //商城id
      businessType: (state) => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      showWay: (state) => state.config.show_way, // 商品展示方式，1积分，2现金
      price_status: (state) => state.config.price_status, // 是否展示价格 1展示 0 不展示
      mall_price_status: (state) => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
      integralRate: (state) => state.config.integral_rate, // 积分比例
      tripartite_type: (state) => state.tripartite_type,
    }),
  },
  methods: {
    //处理已售
    getSales,

    //已售显隐
    salesStatus() {
      if (this.bid === 1131 || this.bid === 1134) {
        return true;
      } else {
        return false;
      }
      //return true;
    },

    // 点击商品
    clickItem(item, index) {
      this.$emit("click-item", { ...item, index });
    },

    // 积分3.0显示
    priceShow,
  },
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.item-list {
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 374px) {
    .had-stage {
      height: 5.2rem;
    }
  }

  @media screen and (min-width: 321px) {
    .had-stage {
      height: 5.2rem;
    }
  }

  .no-stage {
    height: 4.8rem;
  }

  .zjg-li {
    height: 5.2rem;
  }

  .item {
    // .wh(47.5%, 4.9rem);
    width: 47.5%;
    position: relative;
    margin-top: 0.3rem;
    padding-bottom: 0.15rem;
    float: left;
    position: relative;
    background: #fff;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.08rem;
    overflow: hidden;
    z-index: 0;

    .img-box {
      .wh(100%, 3.3rem);
      margin-bottom: 0.14rem;
      position: relative;

      .img {
        .wh(100%, 100%);
      }
    }

    .sales {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.1rem;
      padding: 0 0.1rem;
    }

    .item-name {
      height: 0.75rem;
      padding: 0 0.2rem;
      margin-bottom: 0.12rem;
      .sc(0.26rem, rgba(0, 0, 0, 0.87));
      line-height: 0.36rem;
      word-break: break-all;
      word-wrap: break-word;
      .ellipsis(2);

      .sourceName {
        position: relative;
        top: -0.02rem;
        display: inline-block;
        height: 0.36rem;
        line-height: 0.36rem;
        padding: 0 0.15rem;
        border-radius: 0.18rem;
        background-color: var(--main-color);
        color: #ffffff;
        font-size: 0.18rem;
      }
    }

    .zjg-max {
      color: var(--main-color);
      padding-left: 0.2rem;
      font-size: 0.2rem;
    }

    .priceWithBtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.2rem;

      .item-price {
        padding-left: 0.2rem;
        /* position: absolute;
				bottom: 0.26rem; */

        .price {
          color: @color-dark-grey;
          line-height: 0.28rem;
          font-weight: bold;

          .sign {
            font-size: @fontsize-small;
          }

          .num {
            font-size: @fontsize-large;
          }
        }
      }

      .buy-btn {
        position: absolute;
        right: 0;
        bottom: 0.17rem;
        .wh(0.9rem, 0.48rem);
        padding-left: 0.26rem;
        padding-top: 0.08rem;
        .sc(@fontsize-small, #fff);
        line-height: 0.34rem;
        font-weight: 500;
        white-space: nowrap;
        background-color: var(--main-color);
        border-radius: 2rem 0px 0px 2rem;
        z-index: 2;
      }
    }

    .lusse {
      padding: 0 0.2rem;
      color: #000;
      // font-size: 0.2rem;

      .plans {
        color: #fe5d1c;
      }

      .money {
        font-size: 0.4rem;
      }

      .original-price {
        margin-top: 0.15rem;
        color: #999999;
      }
    }

    .benefit-wrap {
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
      display: flex;
      align-items: center;
      height: 0.36rem;
      border: 1px solid var(--main-color);
      border-radius: 0.18rem;
      z-index: 2;

      .benefit {
        .sc(0.22rem, var(--main-color));
        line-height: 0.32rem;
        white-space: nowrap;
      }
    }

    &:nth-child(odd) {
      margin-right: 4%;
    }
  }
}
</style>
