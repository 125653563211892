var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "item-list" },
    _vm._l(_vm.itemList, function (item, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "item",
          class: [
            item.display_type === 3 ? "had-stage" : "no-stage",
            _vm.tripartite_type === "VUE_APP_ZJG" ? "zjg-li" : "",
          ],
          on: {
            click: function ($event) {
              return _vm.clickItem(item, index)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "img-box" },
            [
              _c("yqt-image", {
                staticClass: "img",
                attrs: {
                  src: _vm._f("ossProcess")(
                    _vm._f("setHttps")(item.base_pic),
                    "style/w_480_h_auto"
                  ),
                  fit: "cover",
                  lazyload: "",
                  alt: "item pic",
                },
              }),
            ],
            1
          ),
          _c("p", { staticClass: "item-name" }, [
            item.source_type
              ? _c("span", { staticClass: "sourceName" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.getSourceTypes(item.source_type)) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v("\n      " + _vm._s(item.item_name) + "\n    "),
          ]),
          _vm.salesStatus()
            ? _c("p", { staticClass: "sales" }, [
                _c("span", [_vm._v("已售")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.getSales(item.id, item.cost_price))),
                ]),
              ])
            : _vm._e(),
          _vm.price_status == 1 && !item.installment_plans
            ? _c("div", { staticClass: "priceWithBtn" }, [
                _vm.businessType == 2
                  ? _c("p", { staticClass: "item-price" }, [
                      _c("span", { staticClass: "price" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.priceShow(
                                item.display_type,
                                item.price,
                                item.max_integral_percentage
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _c("p", { staticClass: "item-price" }, [
                      _c("span", { staticClass: "price" }, [
                        _c("span", { staticClass: "sign" }, [_vm._v("¥")]),
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(item.price)),
                        ]),
                      ]),
                    ]),
              ])
            : _vm._e(),
          _vm.tripartite_type === "VUE_APP_ZJG" && item.display_type !== 1
            ? _c("div", { staticClass: "zjg-max" }, [
                _vm._v(
                  "\n      积分最高抵扣" +
                    _vm._s(item.max_integral_percentage) +
                    "%\n    "
                ),
              ])
            : _vm._e(),
          item.display_type === 3 && item.installment_plans
            ? _c("div", { staticClass: "lusse" }, [
                _c("p", [
                  _c("span", { staticClass: "plans money" }, [
                    _vm._v(
                      _vm._s(item.installment_plans.slice(-1)[0].avg_price)
                    ),
                  ]),
                  _c("span", { staticClass: "plans" }, [_vm._v("元")]),
                  _vm._v("/月起\n      "),
                ]),
                _vm.businessType == 2
                  ? _c("p", { staticClass: "item-price original-price" }, [
                      _c("span", { staticClass: "price" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.priceShow(
                                item.display_type,
                                item.price,
                                item.max_integral_percentage
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _c("p", { staticClass: "item-price original-price" }, [
                      _c("span", { staticClass: "price" }, [
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(item.price) + "元"),
                        ]),
                      ]),
                    ]),
              ])
            : _vm._e(),
          item.coupon_title
            ? _c(
                "div",
                { staticClass: "benefit-wrap" },
                [
                  _c(
                    "yd-badge",
                    { attrs: { bgcolor: "transparent", color: _vm.theme } },
                    [
                      _c("span", { staticClass: "benefit" }, [
                        _vm._v(_vm._s(item.coupon_title)),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }